<template>
    <div class="result">
      <div class="main-contain">
        <div class="main-center">
          <div class="box-top">
            <el-card class="box-card-details">
              <el-divider content-position="left">
                <img src="../../assets/siteImg/title.png" alt="" style="vertical-align: middle">
                <span style="vertical-align: middle">当前查询序列</span>
              </el-divider>
              <p style="width:100%;word-wrap:break-word;padding:20px;box-sizing: border-box;">{{ querySequence }}</p>
            </el-card>
          </div>
          <div v-loading="loading" element-loading-text="正在查询中......" class="result-box">
            <el-card>
              <el-divider content-position="left">
                <img src="../../assets/siteImg/title.png" alt="" style="vertical-align: middle">
                <span style="vertical-align: center">查询序列结果</span>
              </el-divider>
              <el-table
                :data="detailsTableData"
                :header-cell-style="{background:'#4E86E7',color:'#fff'}"
                tooltip-effect="light"
                style="width: 100%;font-size: 14px;"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="60"
                >
                  <template #default="scope">
                    <span>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="geneBank.strainNumber"
                  width="80"
                  label="菌种编号"
                >
                  <template #default="scope">
                    <span v-if="scope.row.geneBank" style="color:blue;cursor:pointer" @click="openDialog(scope.row)">{{ scope.row.geneBank.strainNumber }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="测序结果"
                  show-overflow-tooltip
                  width="190"
                >
                  <template #default="scope">
                    <!-- <i>{{ scope.row.geneBank.enResult }}</i> -->
                    <span v-if="scope.row.geneBank" v-html="changeResult(scope.row.geneBank.enResult)" />
                  </template>
                </el-table-column>
                <el-table-column
                  width="140"
                  show-overflow-tooltip
                  label="菌株来源文物类别"
                >
                  <template #default="scope">
                    <span v-if="scope.row.geneBank">{{ scope.row.geneBank.bacteriumSource }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="bacteriumAddress" width="120" align="center" show-overflow-tooltip label="菌株来源地点">
                  <template #default="scope">
                    <span v-if="scope.row.geneBank">{{ scope.row.geneBank.bacteriumAddress }}</span>
                    <span v-else>~</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="rate"
                  label="一致性"
                  align="center"
                  width="80"
                >
                  <template #default="scope">
                    {{ scope.row.rate*100 }}%
                  </template>
                </el-table-column>
                <!--<el-table-column-->
                <!--align="center"-->
                <!--prop="missingQuantity"-->
                <!--label="插入/缺失"-->
                <!--width="90"-->
                <!--/>-->
                <el-table-column
                  align="center"
                  show-overflow-tooltip
                  label="输入序列"
                >
                  <template #default="scope">
                    <span>{{ scope.row.querySequence }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  align="center"
                  label="目标序列"
                >
                  <template #default="scope">
                    <span>{{ scope.row.resultSequence }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                layout="total,sizes,prev, pager, next"
                :current-page="detailsPage"
                :page-size="detailsSize"
                :total="detailsTotal"
                background
                style="margin-bottom:24px"
                @current-change="changeDetailsPage"
                @size-change="handleSizeChange"
              />
            </el-card>
          </div>
        </div>
      </div>
      <result-dialog ref="resultDia" :num="detailsTotal" :len="querySequence.length" />
    </div>
  </template>
  
  <script>
  import { getGeneBank, resultgetAll } from '@/api/toolsText'
  import resultDialog from './SequencesResult.vue'
  export default {
    name: 'Result',
    components: {
      'result-dialog': resultDialog
    },
    data() {
      return {
        loading: false,
        querySequence: '',
        curJobId: null,
        detailsTableData: [],
        detailsPage: 1,
        detailsSize: 10,
        detailsTotal: 0
      }
    },
    created() {
      const p = JSON.parse(window.sessionStorage.getItem('obj'))
      this.querySequence = p.str
      if (p.num === 1) {
        this.curJobId = p.job
        this.queryDetils()
      } else {
        this.queryFunc()
      }
    },
    beforeDestroy() {
      window.sessionStorage.removeItem('obj')
    },
    methods: {
      // 查询 生成任务
      queryFunc() {
        this.loading = true
        const formData = new FormData()
        formData.append('querySequence', this.querySequence)
        getGeneBank(formData).then(res => {
          if (res.state === 200) {
            this.curJobId = res.data
          }
        }).then(() => {
          this.queryDetils()
        }).catch(err => {
          this.loading = false
          this.$notify({
            title: '错误提示',
            message: err.response.data.message,
            type: 'warning',
            duration: 2000
          })
        })
      },
      // 查询结果列表
      queryDetils() {
        const params = {
          jobId: this.curJobId,
          page: this.detailsPage,
          size: this.detailsSize
        }
        resultgetAll(params).then(res => {
          if (res.state === 200 && res.data.jobList.state === 1) {
            this.loading = false
            if (res.data.jobResultList.list.length > 0) {
              this.detailsTableData = res.data.jobResultList.list
              this.detailsTotal = res.data.jobResultList.total
            } else {
              this.detailsTotal = 0
              this.detailsTableData = []
            }
          } else {
            this.queryDetils()
          }
        }).catch(err => {
          this.loading = false
          this.$notify({
            title: '错误提示',
            message: err.response.data.message,
            type: 'warning',
            duration: 2000
          })
        })
      },
      openDialog(rowData) {
        this.$refs.resultDia.dataGetter(rowData)
        this.$refs.resultDia.dialogResultVisible = true
      },
      // 任务结果page变化
      changeDetailsPage(p) {
        this.detailsPage = p
        this.queryDetils(this.curJobId)
      },
      handleSizeChange(s) {
        this.detailsSize = s
        this.detailsPage = 1
        this.queryDetils(this.curJobId)
      },
      changeResult(data) {
        let str = ''
        if (data.indexOf('sp.') > -1) {
          const arr = data.split('sp.')
          for (const item of arr) {
            if (item) {
              str = str + `<i>${item}</i><span>sp.</span>`
            }
          }
        } else {
          str = `<i>${data}</i>`
        }
        return str
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
    .result {
      padding-top: 60px;
      overflow-y: auto;
      padding-bottom: 50px;
      .main-contain {
        width: 100%;
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        .main-center {
          width: 73.61%;
          .el-divider {
            background-color: #134AAA;
          }
          .el-divider__text {
            color: #134AAA;
            padding: 0 10px 0 0px
          }
          .el-divider__text.is-left {
            font-size:16px;
            left: 0px;
          }
          .el-divider__text {
            font-size: 16px;
            font-weight: bold;
            font-style: italic;
          }
          .box-top {
            position: relative;
            &::after {
            content: url("../../assets/siteImg/title.png");
            width: 40px;
            height: 37px;
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 1;
            }
            &::before {
            content: url("../../assets/siteImg/title.png");
            width: 40px;
            height: 37px;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 1;
            transform: rotateY(180deg);
            }
          }
          .result-box {
            margin-top: 34px;
            .table-search {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .total {
                font-size: 16px;
                color: #666;
              }
              .search {
                .el-input__inner {
                  height: 36px;
                  line-height: 36px;
                  border: 1px solid #4E86E7;
                }
                .el-input-group__append {
                  background-color: #4E86E7;
                  border: 1px solid #4E86E7;
                  .el-icon-search {
                    font-size: 20px;
                    color: #fff;
                  }
                }
              }
            }
            .el-table {
              margin: 20px 0 15px;
            }
            .el-pagination {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  </style>
  