<template>
    <el-dialog 
    title="序列结果详细" 
    v-model="dialogResultVisible"
    width="80%"
    @close="cancel">
      <div class="detilsCard">
        <div class="count">
          <span class="item">序列长度：{{ len }}</span>
          <!--<span class="item">匹配数量：{{ num }}</span>-->
        </div>
        <div v-if="JSON.stringify(resultData) != '{}'" class="middel">
          <!--<div class="itemcount">-->
          <!--<strong class="num">{{ resultData.missingQuantity }}</strong><br>-->
          <!--缺失数量-->
          <!--</div>-->
          <div class="itemcount">
            <strong class="num">{{ resultData.matchingQuantity }}</strong><br>
            一致数量
          </div>
          <div class="itemcount">
            <strong class="num">{{ resultData.rate*100 }}</strong>（%）<br>
            一致性
          </div>
          <div class="itemcount">
            <strong class="num">{{ resultData.geneBank.strainNumber }}</strong><br>
            菌种编号
          </div>
        </div>
        <div v-if="JSON.stringify(resultData) != '{}'" class="middel2">
          <div>
            <strong class="num2">测序结果：</strong>
            <!-- {{ resultData.geneBank.enResult }} -->
            <span v-html="changeResult(resultData.geneBank.enResult)" />
          </div>
          <div>
            <strong class="num2">菌株来源文物类别：</strong>
            {{ resultData.geneBank.bacteriumSource }}
          </div>
          <div>
            <strong class="num2">菌株来源地点：</strong>
            {{ resultData.geneBank.bacteriumAddress }}
          </div>
        </div>
        <div class="bottom">
          <!-- 氨基酸输入和目标序列一一对比 每行length组循环展示 start-->
          <div v-for="(i , indexs) in resultData.querySequence" :key="indexs" style="width:100%;margin-bottom:20px">
            <p class="clounms clounms-query">
              <span class="titles tips tip">Query</span>
              <span class="titles tips tip">{{ resultData.querystart+indexs*lengths }}</span>
              <span class="tips tip">
                <span v-for="(j, index) in i" :key="index">
                  <div :class="{'red': j != resultData.resultSequence[indexs][index]}" class="letter">{{ j }}</div>
                </span>
              </span>
              <span class="tips titles">{{ resultData.querystart+(indexs*lengths)+i.length-1 }}</span>
            </p>
            <p class="clounms">
              <span class="titles tips tip  none">Query</span>
              <span class="titles tips tip none">199</span>
              <span class="tips tip">
                <span v-for="(j, index) in i" :key="index">
                  <span :class="{'red': j != resultData.resultSequence[indexs][index]}" class="letter line">|</span>
                </span>
              </span>
              <span class="tips none titles">{{ i.length }}</span>
            </p>
            <p class="clounms clounms-sbjct">
              <span class="titles tips tip">Sbjct</span>
              <span class="titles tips tip">{{ resultData.Sbjctstart+indexs*lengths }}</span>
              <span class="tips tip">
                <span v-for="(j, index) in resultData.resultSequence[indexs]" :key="index">
                  <div :class="{'red': j != resultData.querySequence[indexs][index]}" class="letter">{{ j }}</div>
                </span>
              </span>
              <span class="tips titles">{{ resultData.Sbjctstart+(indexs*lengths)+resultData.resultSequence[indexs].length-1 }}</span>
            </p>
          </div>
          <!-- 氨基酸序列一一对比 循环展示 end-->
        </div>
      </div>
    </el-dialog>
  </template>
  
  <script>
  export default {
    name: 'SequencesResult',
    props: {
      num: {
        type: Number // c匹配数量
      },
      len: {
        type: Number // 序列长度
      }
    },
    data() {
      return {
        dialogResultVisible: false,
        arrs: [], // result[i]中每一项处理后的存放位置
        resultData: {}, // result数组处理后得到的新数组
        lengths: 50 // 氨基酸序列对比每行对比组
      }
    },
    methods: {
      dataGetter(data) {
        const obj = {
          Sbjctstart: '',
          querystart: '',
          querySequence: '',
          resultSequence: '',
          // missingQuantity: '',
          geneBank: {
            strainNumber: '',
            enResult: '',
            cnResult: '',
            bacteriumSource: ''
          }
        }
        obj.Sbjctstart = this.startPosition(data.queryStartToEnd)
        obj.querystart = this.startPosition(data.resultStartToEnd)
        obj.querySequence = this.STRINGArry(data.querySequence, this.lengths)
        obj.resultSequence = this.STRINGArry(data.resultSequence, this.lengths)
        // obj.missingQuantity = data.missingQuantity
        obj.matchingQuantity = data.matchingQuantity
        obj.rate = data.rate
        obj.geneBank.strainNumber = data.geneBank.strainNumber
        obj.geneBank.enResult = data.geneBank.enResult
        obj.geneBank.cnResult = data.geneBank.cnResult
        obj.geneBank.bacteriumSource = data.geneBank.bacteriumSource
        this.resultData = obj
      },
      startPosition(stratString) {
        return Number(stratString.split('-')[0])
      },
      STRINGArry(str, length) {
        this.arrs = []
        this.STRINGSPLIT(str, length) // 字符串每length个截取
        return this.arrs
      },
      STRINGSPLIT(str, length) {
        if (str.length > length) {
          const newstr = str.substring(0, length)
          str = str.substring(length)
          this.STRINGSPLIT(str, length)
          this.arrs.unshift(newstr.split(''))
        } else {
          const newstr = str.substring(0, str.length)
          this.arrs.unshift(newstr.split(''))
          return
        }
      },
      // 关闭
      cancel() {
        this.dialogResultVisible = false
        this.arrs = []
        this.resultData = {}
      },
      changeResult(data) {
        let str = ''
        if (data.indexOf('sp.') > -1) {
          const arr = data.split('sp.')
          for (const item of arr) {
            if (item) {
              str = str + `<i>${item}</i><span>sp.</span>`
            }
          }
        } else {
          str = `<i>${data}</i>`
        }
        return str
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
    /deep/.el-dialog__body {
      max-height: 70vh;
      overflow-y: auto;
    }
    .item {
      margin-right: 30px;
      font-size: 14px;
      color: grey;
    }
  
    .count {
      background: #f0f0f1;
      width: 100%;
      padding-left: 24px;
      box-sizing: border-box;
      height: 40px;
      line-height: 40px;
    }
  
    .detilsCard {
      width: 100%;
    }
  
    .itemcount {
      text-align: center;
      min-width: 50px;
    }
  
    .middel {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 20px 102px;
      border-bottom: #e9e9f3 1px solid;
      box-sizing: border-box;
    }
  
    .middel2 {
      width: 100%;
      padding: 20px 102px;
      border-bottom: #e9e9f3 1px solid;
      line-height: 30px;
      box-sizing: border-box;
    }
  
    .num {
      font-size: 24px;
    }
  
    .num2 {
      font-size: 16px;
    }
  
    .bottom {
      width: 100%;
      padding: 20px 0px;
    }
  
    .clounms {
      width: 100%;
      text-align: center;
      margin: 0;
    }
    .clounms-query {
      margin-top: 10px;
    }
    .clounms-sbjct {
      margin-bottom: 10px;
    }
  
    .tips {
      display: inline-block;
      text-align: center;
    }
  
    .tip {
      margin-right: 0px;
    }
  
    .letter {
      display: inline-block;
      width: 13px;
      text-align: center;
    }
  
    .line {
      font-size: 12px;
    }
    .red {
      color: #ff0000;
      font-weight: 900;
    }
  
    .none {
      visibility: hidden;
    }
  
    .titles {
      display: inline-block;
      width: 70px;
    }
  </style>
  